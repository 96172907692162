import React from "react"
import { graphql } from "gatsby"
import { Routes } from "../../data/routes"
import Seo from "../../components/seo"

const Content = {
  pageName: "Teacher Announcements",
}

type Announcement = {
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    date: string
    title: string
  }
}

function AnnouncementsSection(props: { announcements: Array<Announcement> }) {
  // Original Tailwind UI component: https://tailwindui.com/components/application-ui/lists/stacked-lists#component-873e7c6c9d62158fc888708dc1dcfa03
  return (
    <div className="max-w-4xl mx-auto">
      <ul role="list" className="divide-y divide-gray-100 px-4">
        {props.announcements.map((announcement: Announcement) => (
          <li
            key={announcement.frontmatter.title}
            className="py-5 px-2 hover:bg-gray-50"
          >
            <a
              target="_blank"
              href={announcement.fields.slug}
              className="w-full"
            >
              <div className="flex justify-between sm:space-x-3 flex-col sm:flex-row">
                <div className="min-w-0 flex-1 font-bold text-secondary">
                  {announcement.frontmatter.title}
                </div>
                <time
                  dateTime={announcement.frontmatter.date}
                  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                >
                  {announcement.frontmatter.date}
                </time>
              </div>
              <div className="mt-2">
                <p className="line-clamp-2 text-sm text-gray-500">
                  {announcement.excerpt}
                </p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

const TeacherAnnouncements = (props: { data: any }) => {
  const announcements = props.data.allMarkdownRemark.nodes
  return (
    <main className="pb-12 min-h-screen">
      <Seo title={Content.pageName} route={Routes.teacherAnnouncements} />
      <AnnouncementsSection announcements={announcements} />
    </main>
  )
}

export default TeacherAnnouncements

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "teacher-announcements" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
      }
    }
  }
`
